import { Ecosystem } from "../components";
import "../styles/Welcome.scss";

export const Welcome = () => {
  return (
    <article id="welcome" className="row">
      <div className="col">
        <Ecosystem />
      </div>

    </article>
  );
};
