import React from 'react';



const ecosystemArray = [
    {
        name: 'BlockStar Brand Guide',
        link: 'BlockStar-Zipped.zip'
    },
    {
        name: 'BlockStar Academy',
        link: 'BlockStar-Academy-Zipped.zip'
    },
    {
        name: 'BlockStar Agency',
        link: 'BlockStar-Agency-Zipped.zip'
    },
    {
        name: 'BlockStar Altitude',
        link: 'BlockStar-Altitude-Zipped.zip'
    },
    {
        name: 'BlockStar Animals',
        link: 'BlockStar-Animals-Zipped.zip'
    },
    {
        name: 'BlockStar Bears',
        link: 'BlockStar-Bears-Zipped.zip'
    },
    {
        name: 'BlockStar Casino',
        link: 'BlockStar-Casino-Zipped.zip'
    },
    {
        name: 'BlockStar Chain',
        link: 'BlockStar-Chain-Zipped.zip'
    },
    {
        name: 'BlockStar Chat',
        link: 'BlockStar-Chat-Zipped.zip'
    },
    {
        name: 'BlockStar Create',
        link: 'BlockStar-Create-Zipped.zip'
    },
    {
        name: 'BlockStar CRM',
        link: 'BlockStar-CRM-Zipped.zip'
    },
    {
        name: 'BlockStar Crypto',
        link: 'BlockStar-Crypto-Zipped.zip'
    },
    {
        name: 'BlockStar DAISI',
        link: 'BlockStar-DAISI-Zipped.zip'
    },
    {
        name: 'BlockStar Digital',
        link: 'BlockStar-Digital-Zipped.zip'
    },
    {
        name: 'BlockStar HEX',
        link: 'BlockStar-HEX-Zipped.zip'
    },
    {
        name: 'BlockStar Imagine',
        link: 'BlockStar-Imagine-Zipped.zip'
    },
    {
        name: 'BlockStar Kids',
        link: 'BlockStar-Kids-Zipped.zip'
    },
    {
        name: 'BlockStar Meet',
        link: 'BlockStar-Meet-Zipped.zip'
    },
    {
        name: 'BlockStar Podcast',
        link: 'BlockStar-Podcast-Zipped.zip'
    },
    {
        name: 'BlockStar Poker',
        link: 'BlockStar-Poker-Zipped.zip'
    },
    {
        name: 'BlockStar Social',
        link: 'BlockStar-Social-Zipped.zip'
    },
    {
        name: 'BlockStar Store',
        link: 'BlockStar-Store-Zipped.zip'
    },
    {
        name: 'BlockStar TV',
        link: 'BlockStar-TV-Zipped.zip'
    },
    {
        name: 'BlockStar Upload',
        link: 'BlockStar-Upload-Zipped.zip'
    },
    {
        name: 'BlockStar World',
        link: 'BlockStar-World-Zipped.zip'
    }
];


export default function BrandAssets() {
    return (
        <div className='w-full d-flex justify-content-center'>
            <div class="relative overflow-x-auto" style={{ width: "60%" }}>
                <table class="w-full text-sm text-left rtl:text-right text-gray-400">
                    <thead class="text-xs text-white uppercase bg-gray-700 text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                #
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Platform Name
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Download
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {ecosystemArray && ecosystemArray.map((item, key) => {
                            return (
                                <tr class="bg-gray-800 hover:bg-gray-600" key={key}>
                                    <td class="px-6 py-4 whitespace-nowrap text-white">
                                        {key + 1}
                                    </td>
                                    <td class="px-6 py-4  text-white">
                                        {item.name}
                                    </td>
                                    <td class="px-6 py-4">
                                        <a href={item.link}
                                            download
                                            target="_blank"
                                            rel="noreferrer" style={{ "display": "inline-flex" }} className='hover:text-blue-600'>
                                            <svg className='mx-2' viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                                            Download
                                        </a>
                                    </td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            </div>


        </div>
    )
}
