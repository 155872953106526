import "../styles/Ecosystem.scss";
import { Link } from "react-router-dom";
import EntertainmentRedJewel from '../assets/EntertainmentRedJewel.png';
import SocialBlueSapphire from '../assets/SocialBlueSapphire.png';
import AITurquoiseJewel from '../assets/AITurquoiseJewel.png';
import FinanceGreenJewel from '../assets/FinanceGreenJewel.png';
import GamingOrangeJewel from '../assets/GamingOrangeJewel.png';
import ShoppingPurpleJewel from '../assets/ShoppingPurpleJewel.png';
import ToolsBrownJewel from '../assets/ToolsBrownJewel.png';
import LearningGrayJewel from '../assets/LearningGrayJewel.png';
import KidsJewel from '../assets/KidsJewel.png';


const ecosystemArray = [
  {
    header: "Social",
    ribbion: "Social",
    class: "social-hover",
    span: "span-social",
    ribcolor: "span-social",
    description: 'BlockStar Social is the place to connect with family and friends and for meeting new people, chatting, and sharing life’s updates. Join from anywhere, bring your stories, start a conversation, and be part of a thriving global community.',
    link: "/social",
    img: SocialBlueSapphire,
    is_new: 2,
    link_type: 1
  },
  {
    header: "Entertainment",
    ribbion: "Entertainment",
    class: "entertainment-hover",
    span: "span-tv",
    ribcolor: "span-tv",
    description:
      "BlockStar Entertainment offers a wide range of videos, music, podcasts, and TV shows. It's a great source for decentralized entertainment, with something for everyone, and you can access it anytime, anywhere.",
    link: "/entertainment",
    img: EntertainmentRedJewel,
    is_new: 2,
    link_type: 2
  },
  {
    header: "AI",
    ribbion: "AI",
    class: "ai-hover",
    span: "span-daisi",
    ribcolor: "span-daisi",
    description:
      "BlockStar AI is your gateway to incorporating artificial intelligence into your everyday life. With user-friendly systems, BlockStar brings together tools designed for productivity, creativity, learning, and entertainment, all enhanced by AI.",
    link: "/ai",
    img: AITurquoiseJewel,
    is_new: 2,
    link_type: 2
  },
  {
    header: "Finance",
    ribbion: "Finance",
    class: "finance-hover",
    span: "span-hex",
    ribcolor: "span-hex",
    description:
      'BlockStar Finance is the premier online destination for digital asset management providing a simple interface for trading crypto, staking, and real-time analytics. BlockStar is decentralized so your digital assets always remain in your control.',
    link: "/finance",
    img: FinanceGreenJewel,
    is_new: 1,
    link_type: 2
  },
  {
    header: "Gaming",
    ribbion: "Gaming",
    class: "gaming-hover",
    span: "span-casino",
    ribcolor: "span-casino",
    description:
      "Step into the realm of BlockStar Gaming and discover a dynamic playground for blockchain-based games. Whether you're looking for strategic challenges, pure enjoyment, or the thrill of rewards, you'll find a game that suits your style.",
    link: "/gaming",
    img: GamingOrangeJewel,
    is_new: 2,
    link_type: 2
  },
  {
    header: "Shopping",
    ribbion: "Shopping",
    class: "shopping-hover",
    span: "span-utility",
    ribcolor: "span-utility",
    description:
      "Explore BlockStar Shopping for high quality goods crafted with the latest fashion, tech and lifestyle trends, all in one place. BlockStar Shopping features an extensive selection of quality products, services, and hard to find collectibles.",
    link: "/shopping",
    img: ShoppingPurpleJewel,
    is_new: 1,
    link_type: 1
  },
  {
    header: "Tools",
    ribbion: "Tools",
    class: "tools-hover",
    span: "span-utility",
    ribcolor: "span-tools",
    description:
      'BlockStar Tools optimizes business through collaboration, video conferencing, and groundbreaking simultaneous cross-platform streaming capabilities. Unparalleled blockchain-based solutions enable complete customer and data management.',
    link: "/tools",
    img: ToolsBrownJewel,
    is_new: 1,
    link_type: 1
  },
  {
    header: "Learning",
    ribbion: "Learning",
    class: "learning-hover",
    span: "span-academy",
    ribcolor: "span-academy",
    description:
      'Expand your knowledge with BlockStar Learning. We offer a wide variety of courses, including college credit opportunities. Dive into our large repository of educational materials, and follow a learning path aligned with your interests and goals.',
    link: "/learning",
    img: LearningGrayJewel,
    is_new: 1,
    link_type: 2
  },

  {
    header: "Kids",
    ribbion: "Kids",
    class: "kids--hover",
    span: "span-utility",
    ribcolor: "span-kids",
    description: 'Dive into BlockStar Kids, a safe space to discover and explore. Engage with interactive games, absorb new knowledge, and create with AI - all within a trusted blockchain framework.',
    link: "/kids",
    img: KidsJewel,
    is_new: 1,
    link_type: 2
  },
];

export const Ecosystem = () => {
  return (
    <div className="ecosystem">
      {ecosystemArray.map((item, index) => {
        return (


          <Link
            to={item.link}
            className={item.class}
            key={index}
          >
            <img src={item.img} className="land-img" alt="ai" />
            <div class="ribbon ribbon-top-left-main">
              {/* <span className="span-white">{item.ribbion}</span> */}
              <span className={`span-white ${item.ribcolor}`}></span>

            </div>
            {/* {item.is_new === 1 && */}
            <div class="ribbon ribbon1  ribbon-bottom-right">
              <span className={`span-white ${item.ribcolor}`}></span>
            </div>
            {/* } */}
            {/* <h2 className="text-center">{item.header}</h2> */}
            <p>{item.description}</p>
          </Link>



        );
      })}
    </div>
  );
};
